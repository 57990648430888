const nodemailer = require('nodemailer');
const config = require('../config/config');
const logger = require('../config/logger');
const { generateSixDigitCode, SaveCodeInDB } = require('../utils/codeManager');
const { makeEmailContent } = require('../utils/maleAllEmailContent');

// var transport = nodemailer.createTransport({ service: 'gmail', secure:false,auth: { user: 'ds.noreply.map@gmail.com', pass: 'bgrlepwizqbsrnab',pool: true },tls: {
//   rejectUnauthorized: false
// } });

const transport = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: 'ds.xtocks@gmail.com',
    pass: 'ukbjtxrhyijtnacg',
  },
  // secure: true, // Use secure connection (SSL)
  tls: {
    rejectUnauthorized: false, // Disable certificate validation (not recommended for production)
  },
});

// const transport = nodemailer.createTransport(config.email.smtp);

/* istanbul ignore next */
if (config.env !== 'test') {
  transport
    .verify()
    .then(() => logger.info('Connected to email server'))
    .catch(() => logger.warn('Unable to connect to email server. Make sure you have configured the SMTP options in .env'));
}

/**
 * Send an email
 * @param {string} to
 * @param {string} subject
 * @param {string} text
 * @returns {Promise}
 */
const sendEmail = async (to, subject, html) => {
  console.log("tls", config.email)
  const msg = {
    from: {
      name: "xtocks.io",
      address: config.email.from,
    }, to, subject, html
  };
  await transport.sendMail(msg);
};
const SendCodeByEmail = async (user, token) => {
  let code = generateSixDigitCode();
  // let saved = SaveCodeInDB(user.id="64674df0ee61e5a5d4b7e8c4",code,user.email="abdul.rehman@dimensionalsys.com",'2FA_Code');
  let saved = SaveCodeInDB(user.id, code, user.email, "2FA_Code", token);
  let emailContentType = "2FA_content";
  let dynamicContent = {
    code: code,
    username: user.firstName,
  };
  let EmailContent = await makeEmailContent(emailContentType, dynamicContent);
  let sent = sendEmail(
    user.email,
    "Your Two-Factor Authentication Code",
    EmailContent
  );
  if (sent) {
    return true;
  }
};

/**
 * Send reset password email
 * @param {string} to
 * @param {string} token
 * @returns {Promise}
 */
const sendResetPasswordEmail = async (to, token) => {
  const subject = 'Reset password';
  // replace this url with the link to the reset password page of your front-end app
  const resetPasswordUrl = `https://xtocks.io/reset-password?token=${token}`;
  console.log(resetPasswordUrl);
  const text = `<div>
  <p style="font-size:16px; margin-top:10px; margin-left:10px;">Hi!<br/><br/>This email is to confirm that you requested a password reset,
  to complete the password reset process, click the link below</p>
  <button style="background-color:#FED500; outline:none; border:none; padding:5px; border-radius:2px; margin-left:10px;
  cursor:pointer;"><a href="${resetPasswordUrl}"
  style="text-decoration:none;color:#000;">RESET YOUR PASSWORD</a>
  </button>



  </div>`;

  await sendEmail(to, subject, text);
};

/**
 * Send verification email
 * @param {string} to
 * @param {string} token
 * @returns {Promise}
 */
const sendVerificationEmail = async (to, token) => {
  const subject = 'Email Verification';
  // replace this url with the link to the email verification page of your front-end app
  const verificationEmailUrl = `https://xtocks.io/signup-detail?token=${token}`;
  console.log(verificationEmailUrl);
  const text = `
<div>
  <p style="font-size:16px; margin-top:10px; margin-left:10px;">Hi!<br/><br/>Please confirm your email address by clicking the button below. This is so you can unlock access to your new Xtocks account. If you don't verify your email address within 14 days we'll have to automatically delete your account (and nobody wants that).</p>
  <button style="background-color:#FED500; outline:none; border:none; padding:5px; border-radius:2px; margin-left:10px;
  cursor:pointer;"><a href="${verificationEmailUrl}"
  style="text-decoration:none;color:#000;">VERIFY EMAIL</a>
  </button>



  </div>


`;
  await sendEmail(to, subject, text);
};
const SendUserDetailsToAdmin = async (to, user) => {
  const subject = 'User Details';
  // replace this url with the link to the email verification page of your front-end app
  // const verificationEmailUrl = `https://xtocks.io/signup-detail?token=${token}`;
  // console.log(verificationEmailUrl);
  const text = `
<div>
  <p style="font-size:16px; margin-top:10px; margin-left:10px;">Hi Admin!<br/><br/>Following is the email of new user who is trying to signup with Xtocks.</p>
  <p style="font-size:16px; margin-top:10px; margin-left:10px;">Email : ${user}</p>
  </div>


`;
  await sendEmail(to, subject, text);
};



module.exports = {
  transport,
  sendEmail,
  sendResetPasswordEmail,
  sendVerificationEmail,
  SendCodeByEmail,
  SendUserDetailsToAdmin
};
