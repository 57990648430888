const express = require('express');
const router = express.Router();
const passport = require('passport')



// router.get('/auth/facebook',passport.authenticate('facebook',{scope:'email'}))
// router.get('/failed/login',(req,res)=>{res.send('login failed')})
// router.get('/auth/facebook',passport.authenticate('facebook',{scope:'email'}))

module.exports = router;

