const { CodeSchema } = require("../models/code.modal");

const  generateSixDigitCode=()=> {
    const min = 100000;
    const max = 999999;
    const timestamp = new Date().getTime().toString();
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    const code = (timestamp.substr(-4) + random).substr(-6);
    return code;
  }

  const SaveCodeInDB = async (userId, code, email,codeType,token) => {
    //find and inactive all previous status before creating new
    let resultOfFind = await CodeSchema.updateMany(
      { codeType: codeType, UserId: userId },
      { $set: { status: "inactive" } }
    );
    console.log("update", resultOfFind);
    
  
    const currentDate = new Date();
    const expiryDate = new Date(currentDate.getTime() + 5 * 60000);
    let res = await CodeSchema.create({
      UserId: userId,
      Code: code,
      CreateDate: currentDate,
      ExpiryDate: expiryDate, //five minutes after,
      email: email,
      codeType: codeType,
      token:token
    });
    return res;
  };
  const checkExpiryOfCode = async (id, code, email,codeType) => {
    try {
      let find = await CodeSchema.findOne({
        UserId: id,
        status: "active",
        ExpiryDate: { $gt: new Date() },
        codeType:codeType
      });
      console.log("find of verify", find);
      if (find) {
        if (
          find.Code === code &&
          find.status === "active" &&
          find.ExpiryDate > new Date() &&
          find.email === email
        ) {
          return true;
        } else {
          return false;
        }
      }
      else{
        return false
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  module.exports={
    generateSixDigitCode,
    SaveCodeInDB,
    checkExpiryOfCode
  }