const { seoData } = require("./SeoData");

const pagesDetail = (urlName)=>{
  const url = urlName?.split('/')[1]
  const result = seoData[url]
  console.log("result==>",result)
  if(result){
    return result
  }else{
    return seoData['home']
  }

}

module.exports = {
    pagesDetail
}