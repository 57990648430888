let Stripe = require('stripe');
let { productsConfig } = require('../utils/product.config');
const { User } = require('../models');

let stripe;
let getStripe = () => {
  stripe = stripe ? stripe : new Stripe(process.env.STRIPE_SECRET_KEY);
  return stripe;
};

const stripeProduct = async (req, res) => {
  console.log(productsConfig);

  let offeredProducts = [];

  let stripe_products = Object.keys(productsConfig);
  console.log(stripe_products);

  let products = await getStripe().products.list({
    ids: stripe_products,
  });
  console.log(products);

  for (const p of stripe_products) {
    const product = products.data.find((d) => d.id === p);

    let localProduct = {};
    localProduct.id = product.id;
    localProduct.describtion = product.description;
    localProduct.image = product.images[0];
    localProduct.name = product.name;

    const prices = await getStripe().prices.list({
      product: product.id,
      active: true,
    });
    // console.log(prices);
    let pricing = [];
    for (const price of prices.data) {
      let p = {};
      p.id = price.id;
      p.price = price.unit_amount;
      p.recurring = price.type === 'recurring';
      if (price.type === 'recurring' && price.recurring.interval === 'year' && price.recurring.interval_count == 1) {
        p.label = 'Yearly';
        p.time_period = 'year';
      }
      if (price.type === 'recurring' && price.recurring.interval === 'month' && price.recurring.interval_count == 3) {
        p.label = 'Quarterly';
        p.time_period = 'month';
      }
      if (price.type === 'recurring' && price.recurring.interval === 'month' && price.recurring.interval_count == 6) {
        p.label = 'Half Yearly';
        p.time_period = 'month';
      }
      if (price.type === 'recurring' && price.recurring.interval === 'month' && price.recurring.interval_count == 1) {
        p.label = 'Monthly';
        p.time_period = 'month';
      }
      p.recurring_interval_count = price.recurring.interval_count;

      pricing.push(p);
    }
    localProduct.pricing = pricing;
    offeredProducts.push(localProduct);
  }
  console.log(offeredProducts);
  return res.json(offeredProducts);
};
const create_stripe_customer = async (email) => {
  const customer = await getStripe().customers.create({
    email: email,
    name: email,
  });
  return customer;
};
const create_trial_subscription = async (customerId, planId) => {
  const subsribtion = await getStripe().subscriptions.create({
    customer: customerId,
    items: [{ price: planId }],
    trial_period_days: 7,
  })
  return subsribtion
};
const create_card_source = async (customerId, cardToken) => {
  const source = await getStripe().customers.createSource(
    customerId,
    {
      source: cardToken,
    }
  );
  return source
};

const delete_stripe_customer = async () => {

};
const updateSubscribtion = async (user, planId, productId) => {
  if (user) {
    if (productId && planId) {
      console.log("new subscribtion ha");
      var preSubscribtionForDlete = user.subscriptionId;
      try {
        const previousSubscriptionDetails =
          await getStripe().subscriptions.retrieve(preSubscribtionForDlete);
        console.log(planId, user.plan, previousSubscriptionDetails.status)

        if (
          planId == user.price &&
          previousSubscriptionDetails.status === "active"
        ) {
          console.log("active ha")
          return {
            status: false,
            message: "You are already in this subscribtion!"
          }
        }
        const subscription = await getStripe().subscriptions.create({
          customer: user.customerId,
          items: [{ price: planId }],
        });
        if (subscription) {
          const invoice = await getStripe().invoices.retrieve(
            subscription.latest_invoice
          );
          if (invoice.paid == false) {
            return {
              status: false,
              message: "Transaction failed with respective card"
            }

          } else {
            const deleted = await getStripe().subscriptions.del(
              preSubscribtionForDlete
            );
          }

          let updateLocaDB = await User.findOneAndUpdate(
            { email: user.email },
            {
              price: planId,
              plan: productId,
              subscriptionId: subscription.id
            }
          );
          // console.log("updateLocaDB=====>",updateLocaDB);
          if (updateLocaDB) {
            return {
              status: true,
              message: "update successfully"
            }
          } else {
            const deleted = await getStripe().subscriptions.del(
              preSubscribtionForDlete
            );
          }
          return {
            status: false,
            message: "An error occured"
          }
        } else {
          return {
            status: false,
            message: "error while doing chnaging subscribtion!"
          }
        }
      } catch (err) {
        console.log("err", err);
        return {
          status: false,
          message: "Server error from user"
        }
      }
    } else {
      return {
        status: false,
        message: "Incomplete selection"
      }
    }
  }
};
const addCardService = async (user, cardToken) => {
  const email = user.email;
  let CardInfo = [];
  let source = null;

  try {
    const token = await getStripe().tokens.retrieve(cardToken);
    console.log(token);
    if (token.card && token.card.fingerprint) {
      const cards = await getStripe().customers.listSources(user.customerId, {
        object: "card",
        // limit: 3,
      });
      let cardWithSameFingerPrint = cards.data.find(
        (c) => c.fingerprint === token.card.fingerprint
      );
      if (cardWithSameFingerPrint) {

        return {
          staus: false,
          message: "Card already exist"
        }
      } else {
        source = await getStripe().customers.createSource(user.customerId, {
          source: cardToken,
        });
      }
    } else {
      return {
        staus: false,
        message: "Card source not Found"
      }
    }

    // console.log("source========================>", source);
  } catch (e) {
    console.log("=======>", e);

    return {
      staus: false,
      message: "Invalid credit card information"
    }

  }

  try {
    let result = await User.findOne({ email: email });

    if (result) {

      if (result.cardInfo.length > 0) {
        console.log("result.cardInfo====>", result.cardInfo);
        CardInfo = result.cardInfo;

        CardInfo.push(GetCardObject(source));
        let data = await User.findOneAndUpdate(
          { email },
          { $set: { cardInfo: CardInfo } }
        );
        console.log("data from if=========>", data);
        return {
          staus: true,
          message: "Credit card details have been securely added successfully."
        }

      } else {
        let card = GetCardObject(source);
        card.default = true;
        CardInfo.push(card);
        let data = await User.findOneAndUpdate(
          { email },
          { $set: { cardInfo: CardInfo } }
        );
        console.log("data from else=========>", data);
      }

      console.log("card added");
      return {
        staus: true,
        message: "Credit card details have been securely added successfully."
      }

    } else {
      return {
        staus: false,
        message: "Invalid User."
      }
    }
  } catch (e) {
    // console.log("hello from catch");
    console.log(e);
    return {
      staus: false,
      message: "Server Side Error!"
    }
  
  }

};

const MakeDefaultService = async (user,cardId) => {
  let email = user.email;
  try {
    const customer = await getStripe().customers.update(user.customerId, {
      default_source: cardId,
    });
    console.log(customer);
    if (customer) {
      let result = await User.findOne({ email: email });
      console.log(result);
      //  console.log(result.cardInfo);
      let cardsDetails = result.cardInfo;
      console.log(cardsDetails);
      cardsDetails.map((item) => {
        if (item.cardId === customer.default_source) {
          console.log("item=====>", item);
          if (item["default"]) {
            
            item["default"] = false;
          } else {
          
            item["default"] = true;
            console.log(item);
          }
        } else {
          item.default = false;
        }
      });
  
      let data = await User.findOneAndUpdate(
        { email },
        { $set: { cardInfo: cardsDetails } }
      );
      console.log(data);
      return {status:true,message:"Card make default successfully!"}

   
    }
  } catch (err) {
    return {status:false,message:"Server side error!"}

  }
};

const GetCardObject = (source) => {
  return {
    last4digit: source.last4,
    brand: source.brand,
    cardId: source.id,
    expireYear: source.exp_year,
    expireMonth: source.exp_month,
    cardFingerPrint: source.fingerprint,
  };
};
module.exports = { stripeProduct, create_stripe_customer, delete_stripe_customer, create_trial_subscription, create_card_source, updateSubscribtion,MakeDefaultService, addCardService };
