 let productsConfig=   {
    "prod_MdXheeO7JjzIO7":{
        role:'basic'
    },
    "prod_MdXh25wNIzoAHj":{
        role:'plus'
    },
    "prod_MdXgPqjvFmELJH":{
        role:'premium'
    },   

}
module.exports={productsConfig}