const EmailContents = [
    {
      name: "2FA_content",
      content: `<div  style="height:100%; width:100%;  background-color:black; padding-top:20px;padding-bottom:20px;">
      <img align="center" style="width:300px; margin-top:5px;" border="0" src="">
      <p style="color:white; font-size:16px; margin-top:10px; margin-left:10px;">Hi!<br/><br/>Thank you for using Two-Factor Authentication (2FA) to secure your account. Here is your verification code:</p> 
      <h3 style="margin-left:10px;color:white">{{code}}</h3></br></br>
      <p style="color:white; font-size:16px; margin-top:10px; margin-left:10px;">Please enter this code in the appropriate field to complete the login process. Note that the code is time-sensitive and will expire in 5 minutes. If you did not attempt to log in or initiate this 2FA code, please disregard this email and ensure the security of your account. If you have any questions or need further assistance, please contact our support team.</p><br></br>    
                            </div>.`,
    },
    {
      name: "user_complain",
      content: `<div  style="height:100%; width:100%; background-color:black; padding-top:10px;padding-bottom:20px;">
      <img align="center" style="width:300px;" border="0" src="https://api.marketalertpro.com/images/logo1.png">
      <p style="color:white; font-size:14px; margin-top:2px; margin-left:10px;">Hi {{username}}!<br/><br/>Your support request has been updated. To add additional comments, reply to this email.</p>
      <p style="color:white; font-size:14px;  margin-left:10px;">Complain : {{message}}</p>
     <p style="color:white;font-size:14px; margin-left:10px;">Name : {{username}}</p> 
     <p style="color:white;font-size:14px; text-decoration:none; margin-left:10px;">Email : {{email}}</p> 
     <p style="color:white;font-size:14px; text-decoration:none; margin-left:10px;"> We value your feedback and we will reply you shortly.<br/><br/>Market Alert Pro<br/> 
     Support Team</p>
  
  
                      </div>.`,
    },
    {
      name: "account_create_content",
      content: `Welcome, {{username}}! Click the following link to verify your account: {{verificationLink}}`,
    },
    // Add more email content objects as needed
  ];
  
  const makeEmailContent = (templateName, dynamicValues) => {
    const emailContent = EmailContents.find(
      (content) => content.name === templateName
    );
  
    if (!emailContent) {
      throw new Error("Email template not found.");
    }
  
    let emailBody = emailContent.content;
  
    // Replace placeholders with dynamic values
    Object.entries(dynamicValues).forEach(([placeholder, value]) => {
      const regex = new RegExp(`{{${placeholder}}}`, "g");
      emailBody = emailBody.replace(regex, value);
    });
  
    return emailBody;
  };
  
  module.exports = {
    makeEmailContent,
  };
  