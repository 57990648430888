const dotenv = require('dotenv');
const path = require('path');
const Joi = require('joi');

// dotenv.config();
console.log("node env", process.env.NODE_ENV)
// console.log("node env",process.env)
try {
  if (process.env.NODE_ENV === 'development') {

    dotenv.config({ path: path.join(__dirname, '../../.env') });
  }
  else {
    let fullpath = path.join(__dirname, `.env.${process.env.NODE_ENV}`)
    console.log('fullpath', fullpath)
    dotenv.config({ path: fullpath });

  }
} catch (e) {
  dotenv.config({ path: path.join(__dirname, '.env') });
}

const envVarsSchema = Joi.object()
  .keys({
    PORT: Joi.number().default(3000),
    MONGODB_URL: Joi.string().required().description('Mongo DB url'),
    JWT_SECRET: Joi.string().required().description('JWT secret key'),
    JWT_ACCESS_EXPIRATION_MINUTES: Joi.number().default(30).description('minutes after which access tokens expire'),
    JWT_REFRESH_EXPIRATION_DAYS: Joi.number().default(30).description('days after which refresh tokens expire'),
    JWT_RESET_PASSWORD_EXPIRATION_MINUTES: Joi.number()
      .default(10)
      .description('minutes after which reset password token expires'),
    JWT_VERIFY_EMAIL_EXPIRATION_DAYS: Joi.number()
      .default(10)
      .description('minutes after which verify email token expires'),
    SMTP_HOST: Joi.string().description('server that will send the emails'),
    SMTP_PORT: Joi.number().description('port to connect to the email server'),
    SMTP_USERNAME: Joi.string().description('username for email server'),
    SMTP_PASSWORD: Joi.string().description('password for email server'),
    EMAIL_FROM: Joi.string().description('the from field in the emails sent by the app'),
    FACEBOOK_SECRET_ID: Joi.string().description('the facebook id for signup'),
    FACEBOOK_CLIENT_ID: Joi.string().description('the facebook id for signup'),
    FACEBOOK_CALLBACK_URL: Joi.string().description('the facebook id after signup'),
  })
  .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}

module.exports = {
  env: envVars.NODE_ENV,
  port: envVars.PORT,
  mongoose: {
    url: envVars.MONGODB_URL + (envVars.NODE_ENV === 'test' ? '-test' : ''),
    options: {
      useCreateIndex: true,
      useNewUrlParser: true,
      useUnifiedTopology: true,
    },
  },
  jwt: {
    secret: envVars.JWT_SECRET,
    accessExpirationMinutes: envVars.JWT_ACCESS_EXPIRATION_MINUTES,
    refreshExpirationDays: envVars.JWT_REFRESH_EXPIRATION_DAYS,
    resetPasswordExpirationMinutes: envVars.JWT_RESET_PASSWORD_EXPIRATION_MINUTES,
    verifyEmailExpirationDays: envVars.JWT_VERIFY_EMAIL_EXPIRATION_DAYS,
    two_FA: envVars.JWT_2FA_EXPIRATION_MINUTES,
  },
  email: {
    smtp: {
      host: envVars.SMTP_HOST,
      port: envVars.SMTP_PORT,
      auth: {
        user: envVars.SMTP_USERNAME,
        pass: envVars.SMTP_PASSWORD,
      },
      tls: { rejectUnauthorized: false },
    

    },
    from: envVars.EMAIL_FROM,
  },
  facebookAuth: {
    clientID: envVars.FACEBOOK_CLIENT_ID,
    secretID: envVars.FACEBOOK_CLIENT_ID,
    callbackURL: envVars.FACEBOOK_CALLBACK_URL

  }
};
