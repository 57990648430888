const Joi = require('joi');
const { password } = require('./custom.validation');

const register = {
  body: Joi.object().keys({
    firstname: Joi.string().required(),
    lastname: Joi.string().required(),
    cardToken: Joi.string().required().messages({ message: `Card is required` }),
    plan: Joi.string().required().messages({ message: `Plan is required` }),
    price: Joi.string().required().messages({ message: `Price is required` }),
    token:Joi.string().required()
  }),
};
const update_user = {
  body: Joi.object().keys({
    firstname: Joi.string(),
    lastname: Joi.string(),
    phone: Joi.string()


  }),
};
const update_location = {
  body: Joi.object().keys({
    street: Joi.string(),
    zip: Joi.number(),
    country: Joi.string(),
    state: Joi.string(),
    city: Joi.string(),


  }),
};
const initial_signUp = {
  body: Joi.object().keys({
    email: Joi.string().required().email(),
    password: Joi.string().required().custom(password),
    confirmpassword: Joi.any().equal(Joi.ref('password'))
    .required()
    .messages({ 'any.only': '{{#label}} does not match with password' })
    .custom(password),

    phone: Joi.string()

      .messages({ message: `Phone number must have 10 digits` })
      .optional()
      .allow(''),
  }),
};

const login = {
  body: Joi.object().keys({
    email: Joi.string().required(),
    password: Joi.string().required(),
  }),
};

const change_password = {
  body: Joi.object().keys({
    currentpassword:Joi.string().required().custom(password).required(),
    password: Joi.string().required().custom(password).required(),
    confirmpassword: Joi.any().equal(Joi.ref('password'))
    .required()
    .messages({ 'any.only': '{{#label}} does not match with password' })
    .custom(password)

  }),
};

const logout = {
  body: Joi.object().keys({
    refreshToken: Joi.string().required(),
  }),
};

const refreshTokens = {
  body: Joi.object().keys({
    refreshToken: Joi.string().required(),
  }),
};

const forgotPassword = {
  body: Joi.object().keys({
    email: Joi.string().email().required(),
  }),
};

const resetPassword = {
  body: Joi.object().keys({
    token: Joi.string().required(),
    password: Joi.string().required().custom(password),
  }),
};

const verifyEmail = {
  query: Joi.object().keys({
    token: Joi.string().required(),
  }),
};

module.exports = {
  register,
  login,
  logout,
  refreshTokens,
  forgotPassword,
  resetPassword,
  verifyEmail,
  initial_signUp,
  change_password,
  update_location,
  update_user
};
