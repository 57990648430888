const express = require('express');
const { stripeProduct} = require('../../services/stripe.services');
const { updateSubscription, addCard, MakeDefault } = require('../../controllers/stripe.controller');
const auth = require('../../middlewares/auth');
const router = express.Router();
router.get('/get-stripe-products',stripeProduct)
router.post('/update-subscription',auth(),updateSubscription)
router.post('/addcard',auth(),addCard)
router.post('/make-default',auth(),MakeDefault)
module.exports=router;

