const httpStatus = require('http-status');
const catchAsync = require('../utils/catchAsync');
const bcrypt = require('bcryptjs');
const jwt = require('jsonwebtoken');
const { authService, userService, tokenService, emailService } = require('../services');
const { response } = require('../../src/services/send.response');
const { tokenTypes } = require('../config/tokens');
const stripe_services = require('../services/stripe.services');
const User = require('../models/user.model');
const { SendCodeByEmail } = require('../services/email.service');
const { checkExpiryOfCode } = require('../utils/codeManager');



const initialregister = catchAsync(async (req, res) => {
  await emailService.SendUserDetailsToAdmin("info@dimensionalsys.com", req?.body?.email);
  // await emailService.SendUserDetailsToAdmin("abdul.rehman@dimensionalsys.com", req?.body?.email);
 return res.status(httpStatus.CREATED).send(response(true, 'We just received your request, We will get back to you', {}));

  const payload = req.body;
  payload.password = await bcrypt.hash(payload.password, 8);
  console.log(payload.password);
  const verifyEmailtoken = await tokenService.generateVerifyEmailToken(payload);
  await emailService.sendVerificationEmail(payload.email, verifyEmailtoken);
  res.status(httpStatus.CREATED).send(response(true, 'verification email sent successfully', {}));
});
const Enable2FA = async (req, res) => {
  const Enable2FABy = req.body;
  const user = req.user;
  let obj = [{
    isEnabled: true,
    two_FA_by: Enable2FABy.Enable2FABy
  }]

  try {
    const update = await User.findOneAndUpdate(
      { _id: user.id },
      {
        $set: {
          two_FA: obj
        },
      },

    );

    if (update) {
      return res.send(response(true, "2FA enabled successfully", {}));
    } else {
      return res.send(
        response(false, "Something went wrong please try again later", {})
      );
    }
  } catch (e) {
    console.log(e);
    return res.send(response(false, "Server Error", {}));
  }
};
const Disabled2FA = async (req, res) => {
  // const Enable2FABy = req.body;
  const user = req.user;
  try {
    let update = await User.findByIdAndUpdate(
      { _id: user.id },
      {
        $set: {
          "two_FA.$[elem].isEnabled": false,

        },

      },
      { arrayFilters: [{ "elem.isEnabled": true }], new: false }
    );
    if (update) {
      return res.send(response(true, "2FA diabled successfully", {}));
    } else {
      return res.send(
        response(false, "Something went wrong please try again later", {})
      );
    }
  } catch (e) {
    console.log(e)
    return res.send(response(false, "Server Error", {}));
  }
};
const verifyCodeOf2FA = async (req, res) => {
  try {
    let { token, code } = req.body;
    let decoded = jwt.decode(token);
    console.log("decoded",decoded);
    let result = await User.findOne({ email: decoded.sub });
    const tokens = await tokenService.generateAuthTokens(result);
    console.log("tokens",tokens)
    // if (result?.twoFa?.two_FA_by === "email") {
      let checkExpiry = await checkExpiryOfCode(
        result._id,
        code,
        decoded.sub,
        (codeType = "2FA_Code")
      );
      console.log("checkExpiry",checkExpiry)
      if (checkExpiry) {


        return res.send({ result, tokens });
      }

    // }




  }
  catch (e) {
    console.log(e)
    return res.send(response(false, "Code verification failed", {}));
  }
}

const register = catchAsync(async (req, res) => {
  console.log(req.body)
  const { firstname, lastname, plan, price, token, cardToken } = req.body;
  let userObject = {};
  let CardInfo = [];
  console.log(firstname, lastname, plan, price, token);

  let DetailsInToken = await tokenService.verifyToken(token, tokenTypes.VERIFY_EMAIL);
  if (DetailsInToken) {
    let customer = await stripe_services.create_stripe_customer(DetailsInToken.metadata[0].email);
    if (customer) {
      let subsription = await stripe_services.create_trial_subscription(customer.id, price);
      console.log(subsription);
      if (subsription) {
        console.log("subsription", subsription)
        let source = await stripe_services.create_card_source(customer.id, cardToken);
        if (source) {
          CardInfo.push({
            last4digit: source.last4,
            brand: source.brand,
            cardId: source.id,
            expireYear: source.exp_year,
            expireMonth: source.exp_month,
            cardFingerPrint: source.fingerprint,
            default: true,
          });
          userObject['email'] = DetailsInToken.metadata[0].email;
          userObject['password'] = DetailsInToken.metadata[0].password;
          userObject['phone'] = DetailsInToken.metadata[0].phone;
          userObject['firstname'] = firstname;
          userObject['lastname'] = lastname;
          userObject['plan'] = plan;
          userObject['price'] = price;
          userObject['customerId'] = customer.id;
          userObject['subscriptionId'] = subsription.id;
          userObject['cardInfo'] = CardInfo;
          console.log(userObject);
          const user = await userService.createUser(userObject);
          const tokens = await tokenService.generateAuthTokens(user);
          res.status(httpStatus.CREATED).send({ user, tokens });
        } else {
          return res.send(response(false, 'Server Error', {}));
        }
      }
    } else {
      return res.send(response(false, 'Server Error', {}));
    }
  } else {
    return res.send(response(false, 'Invalid Token', {}));
  }
  return;
});

const login = catchAsync(async (req, res) => {
  const { email, password } = req.body;
  console.log(req.body);
  const user = await authService.loginUserWithEmailAndPassword(email, password);
  const tokens = await tokenService.generateAuthTokens(user);
  // if (
  //   user?.two_FA[0]?.isEnabled &&
  //   user?.two_FA[0]?.two_FA_by === "email"
  // ) {
  //   const twoFA_token = await tokenService.generate2FAToken(user);

  //   let sent = SendCodeByEmail(user, twoFA_token);
  //   if (sent) {
  //     return res.send(
  //       response(
  //         true,
  //         "We just sent you a code kindly verify",
  //         { twoFA: true, token: twoFA_token }
  //       )
  //     );
  //   }
  // }
  // else {
    // return res.send(
    // response(
    //   true,
    //   "We just sent you a code kindly verify",
    //   { user, tokens}
    // )
    // )
    res.send({ user, tokens });

  // }
});


const logout = catchAsync(async (req, res) => {
  await authService.logout(req.body.refreshToken);
  res.status(httpStatus.NO_CONTENT).send();
});

const refreshTokens = catchAsync(async (req, res) => {
  const tokens = await authService.refreshAuth(req.body.refreshToken);
  res.send({ ...tokens });
});

const forgotPassword = catchAsync(async (req, res) => {
  const resetPasswordToken = await tokenService.generateResetPasswordToken(req.body.email);
  await emailService.sendResetPasswordEmail(req.body.email, resetPasswordToken);
  res.status(httpStatus.NO_CONTENT).send();
});

const resetPassword = async (req, res) => {
  console.log("req.body", req.body)
  await authService.resetPassword(req.body.token, req.body.password);
  res.status(httpStatus.NO_CONTENT).send();
};

const sendVerificationEmail = catchAsync(async (req, res) => {
  const verifyEmailToken = await tokenService.generateVerifyEmailToken(req.user);
  await emailService.sendVerificationEmail(req.user.email, verifyEmailToken);
  res.status(httpStatus.NO_CONTENT).send();
});

const changePassword = catchAsync(async (req, res) => {
  let { password, currentpassword } = req.body
  if (req.user) {

    let currentpasswordSame = await bcrypt.compare(currentpassword, req.user.password);
    if (!currentpasswordSame) {
      return res.send(response(false, 'Invalid Current Password', {}))
    }
    else {
      let passwordSame = await bcrypt.compare(password, req.user.password);
      if (passwordSame) {
        return res.send(response(false, 'Password not be same as previous one', {}));
      } else {
        password = await bcrypt.hash(password, 8);
        let updated = await User.findOneAndUpdate({ email: req.user.email }, { $set: { password: password } });
        return res.send(response(true, 'Password updated successfully', {}));
      }
    }
  }
  else {
    return res.send(response(false, 'User not found', {}))
  }
});

const updateUser = catchAsync(async (req, res) => {
  let { firstName, lastName, phone } = req.body;
  let email = req.user.email
  let user = await userService.updateUserByEmail(email, req.body)
  if (!user) {
    return res.send(response(true, 'User Not found', {}));
  }
  else {
    return res.send(response(true, 'Data updated Successfully', {}));


  }

});
const updateLocation = catchAsync(async (req, res) => {
  let email = req.user.email
  let user = await userService.updateLocationInfo(email, [req.body])
  if (!user) {
    return res.send(response(true, 'User Not found', {}));
  }
  else {
    return res.send(response(true, 'Data updated Successfully', {}));
  }
});

const getUserData = async (req, res) => {
  let email = req.user.email
  console.log(req.user)
  let user = await userService.getUserByEmail(email)
  if (!user) {
    return res.send(response(true, 'User Not found', {}));
  }
  else {
    return res.send(response(true, 'Data selected Successfully', { user }));
  }
}
const getLocationData = async (req, res) => {
  let email = req.user.email
  let user = await userService.getUserByEmail(email)
  if (!user) {
    return res.send(response(true, 'User Not found', {}));
  }
  else {
    return res.send(response(true, 'Data selected Successfully', user.location));
  }
}
const verifyEmail = catchAsync(async (req, res) => {
  await authService.verifyEmail(req.query.token);
  res.status(httpStatus.NO_CONTENT).send();
});

module.exports = {
  register,
  login,
  logout,
  refreshTokens,
  forgotPassword,
  resetPassword,
  sendVerificationEmail,
  verifyEmail,
  initialregister,
  changePassword,
  updateUser,
  updateLocation,
  getUserData,
  getLocationData,
  Enable2FA,
  Disabled2FA,
  verifyCodeOf2FA
};
