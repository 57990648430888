const portfolio = require('../models/portfolio.model');
const createPortfolio = async (payload) => {
  try {
    let find = await portfolio.find({ $and: [{ userId: payload.userId }, { name: payload.name }] });
    if (find.length > 0) {
      return false;
    } else {
      let res = await portfolio.create(payload);
      console.log(res);
      return res;
    }
  } catch (e) {
    return e;
  }
};
const get_portfolio_data = async (payload) => {
  try {
    let res = await portfolio.find({ $and: [{ userId: payload.userId }, { name: payload.name }] });
    return res;
  } catch (e) {
    console.log(e);
  }
};
const get_all_portfolios = async (userId) => {
  try {
    let res = await portfolio.find({ userId: userId });
    if (res) {
      return res;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
};
const updatePortfolio = async (payload) => {
  try {
    let find = await portfolio.find({ $and: [{ userId: payload.userId }, { name: payload.name }] });
    let previousData = find[0].data;
    let updatedData = [...previousData, { ...payload.data }];
    let update = await portfolio.findOneAndUpdate(
      { $and: [{ userId: payload.userId }, { name: payload.name }] },
      { $set: { data: updatedData } }
    );
    return update;
  } catch (e) {
    console.log(e);
    return e;
  }
};
const deletePortfolio = async (payload) => {
  try {
    let del = await portfolio.findOneAndDelete({ name: payload.name });
    console.log(del);
    if (del) {
      return true;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
};

module.exports = { createPortfolio, get_portfolio_data, get_all_portfolios, updatePortfolio, deletePortfolio };
