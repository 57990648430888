// const response = require('../utils')
const portfolio = require('../services/portfolio.service');
const { response } = require('../services/send.response');
const createPortfolioController = async (req, res) => {
  const payload = req.body;
  const userId = req.user.id;
  let payloadupdated = { ...payload, userId };
  //   console.log(payload);

  let result = await portfolio.createPortfolio(payloadupdated);

  if (result) {
    if (Object.keys(result).length) {
      res.send(response(true, 'porfolio created', {}));
    } else {
      res.send(response(false, 'An Error Occured', {}));
    }
  } else {
    res.send(response(false, 'already exists', {}));
  }
};
const updatePortfolioController = async (req, res) => {
  const payload = req.body;
  const userId = req.user.id;
  let payloadupdated = { ...payload, userId };

  let result = await portfolio.updatePortfolio(payloadupdated);

  if (result) {
    res.send(response(true, 'porfolio updated', {}));
  } else if (result === null) {
    res.send(response(false, 'Not found', {}));
  } else {
    res.send(response(false, 'An Error Occured', {}));
  }
};
const getPortfolioDataByName = async (req, res) => {
  const payload = req.body;
  const userId = req.user.id;
  let updatedpayload = { ...payload, userId };
  let result = await portfolio.get_portfolio_data(updatedpayload);
  console.log('result', result);
  if (result) {
    res.send(response(true, 'data found successully', result[0].data));
  } else {
    res.send(response(false, 'Not Found', {}));
  }
};
const getAllPortfolioOfUser = async (req, res) => {
  let userId = req.user.id;
  let find = await portfolio.get_all_portfolios(userId);
  console.log(find.length);
  if (find) {
    res.send(response(true, 'data found successully', find));
  } else {
    res.send(response(false, 'Error occured', {}));
  }
};
const deletePortFolioController = async (req, res) => {
  let payload = req.body;
  console.log(payload);
  let del = await portfolio.deletePortfolio(payload);
  if (del) {
    return res.send(response(true, 'Deleted Success', {}));
  } else {
    return res.send(response(false, 'Not found', {}));
  }
};
module.exports = {
  createPortfolioController,
  getPortfolioDataByName,
  getAllPortfolioOfUser,
  updatePortfolioController,
  deletePortFolioController,
};
