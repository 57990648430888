const seoData = {
    scanners:{title:"Scanners",description:"Scan results of 1000+ stocks",keywords:"stock,scanners,drag drop"},
    chart:{title:"Chart",description:"Chart for 1000+ stocks",keywords:"stock,chart,symbol chart"},
    watchlist:{title:"Watchlist",description:"Watchlist for 1000+ stocks",keywords:"watchlist,stock,watchlist results"},
    backtesting:{title:"Backtesting",description:"backtesting of 1000+ stocks",keywords:"backtesting,stock"},
    marketplace:{title:"Marketplace",description:"Market place of your stocks",keywords:"marketplace,stock"},
    portfolio:{title:"Portfolio",description:"Buy stocks portfolio",keywords:"portfolio,stock"},
    news:{title:"News",description:"News related to stocks",keywords:"news,stock"},
    home:{title:"xtocks.io",description:"Place where you can see all the content related to news and market.",keywords:"xtocks,builder,stocks"},
}

module.exports={seoData}

