const mongoose = require('mongoose')
const portfolioSchema = mongoose.Schema({
  userId: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  data: { type: [Object], of: new mongoose.Schema({}) },
});
const portfolio = mongoose.model('Portflio', portfolioSchema);

module.exports = portfolio;


