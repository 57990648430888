const express = require('express');
const helmet = require('helmet');
const xss = require('xss-clean');
const mongoSanitize = require('express-mongo-sanitize');
const compression = require('compression');
const cors = require('cors');
const passport = require('passport');
const FacebookStrategy = require('passport-facebook').Strategy;
const httpStatus = require('http-status');
const config = require('./config/config');
const morgan = require('./config/morgan');
const { jwtStrategy } = require('./config/passport');
const { authLimiter } = require('./middlewares/rateLimiter');
const routes = require('./routes/v1');
const { errorConverter, errorHandler } = require('./middlewares/error');
const ApiError = require('./utils/ApiError');
const path = require('path')
const fs = require('fs');
const { pagesDetail } = require('./utils/SeoDetail');



const app = express();

if (config.env !== 'test') {
  app.use(morgan.successHandler);
  app.use(morgan.errorHandler);
}

// set security HTTP headers
app.use(helmet());

// parse json request body
app.use(express.json());

// parse urlencoded request body
app.use(express.urlencoded({ extended: true }));

// sanitize request data
app.use(xss());
app.use(mongoSanitize());

// gzip compression
app.use(compression());

// enable cors
app.use(cors());
app.options('*', cors());

// jwt authentication
app.use(passport.initialize());
app.use(passport.session());
passport.serializeUser(function (user, cb) {
  cb(null, user);
});

passport.deserializeUser(function (obj, cb) {
  cb(null, obj);
});


passport.use(new FacebookStrategy({
  clientID: config.facebookAuth.clientID,
  clientSecret: config.facebookAuth.clientSecret,
  callbackURL: config.facebookAuth.callbackURL,
  profileField: ['id', 'displayName', 'name', 'gender', 'picture.type(large)', 'email']
}, function (accessToken, refreshToken, profile, done) {
  console.log(profile)
  return done(null, profile);
}
));

app.use(express.static('frontendBuild'));

app.get("*", (req, res) => {
  console.log("req==>", req.url)
  if (req.url.startsWith('/assets')) {
    // local folder
    // const filePath = path.resolve(__dirname, '../frontendBuild', req.url)

    //build folder
    const filePath = path.resolve(__dirname, './frontendBuild', req.url) 
    return res.sendFile(filePath)
  }
  else {
    generateHTMLContent(req, res) 
  }

})
function generateHTMLContent(req, res) {
  const result = pagesDetail(req?.url)
  // local folder
  // const filePath = path.resolve(__dirname, '../frontendBuild', "index.html")

    //build folder
  const filePath = path.resolve(__dirname, './frontendBuild', "index.html")
  let htmlContent = fs.readFileSync(filePath, 'utf-8')

  //replace title
  htmlContent = htmlContent.replace(
    /<title>.*<\/title>/,
    `<title>${result?.title}</title>`
  )

  //replace desc
  htmlContent = htmlContent.replace(
    /<meta name="description".*\/>/,
    `<meta name='description' content='${result?.description}' data-rh="true" data-react-helmet="true" />`
  )
  //replace keywords
  htmlContent = htmlContent.replace(
    /<meta name="keywords".*\/>/,
    `<meta name='keywords' content='${result?.keywords}' />`
  )

  console.log("html content", htmlContent)

  return res.send(htmlContent);
}

// Not found middleware
// app.use((req, res) => {
//   generateHTMLContent(req, res)
// });


passport.use('jwt', jwtStrategy);

// limit repeated failed requests to auth endpoints
if (config.env === 'production') {
  app.use('/v1/auth', authLimiter);
}

// v1 api routes
app.use('/v1', routes);

// send back a 404 error for any unknown api request
app.use((req, res, next) => {
  next(new ApiError(httpStatus.NOT_FOUND, 'Not found'));
});

// convert error to ApiError, if needed
app.use(errorConverter);

// handle error
app.use(errorHandler);



//seo work
// app.use(express.static(path.join(__dirname,'./dist')))




module.exports = app;
