const mongoose  =require( "mongoose");
// var Schema = new mongoose.Schema();
const Codes = new mongoose.Schema({
UserId: {type:String},
CreateDate: {type:Date},
ExpiryDate: {type:Date},
Code: {type:String},
status:{type:String,default:'active'},
email:{type:String},
token:{type:String},
codeType:{type:String}


})
// module.exports = mongoose.model('USerSchem', EventSchema);
module.exports.CodeSchema = mongoose.model('Codes',Codes);