const express = require('express');
const authRoute = require('./auth.route');
const userRoute = require('./user.route');
const docsRoute = require('./docs.route');
const config = require('../../config/config');
const social_auth = require('./socialauth.route')
const stripe_route=require('./stripe.route')
const app_settings_routes = require('./app.setting.routes')
const portfolio = require('./portfolio.route')

const router = express.Router();

const defaultRoutes = [
  {
    path: '/auth',
    route: authRoute,
  },
  {
    path: '/users',
    route: userRoute,
  },
  {
    path: '/social-auths',
    route: social_auth,
  },
  {
    path: '/stripe',
    route: stripe_route,
  },
  {
    path: '/app-settings',
    route: app_settings_routes,
  },
  {
    path: '/portfolio',
    route: portfolio,
  },
];

const devRoutes = [
  // routes available only in development mode
  {
    path: '/docs',
    route: docsRoute,
  },
];

defaultRoutes.forEach((route) => {
  router.use(route.path, route.route);
});

/* istanbul ignore next */
if (config.env === 'development') {
  devRoutes.forEach((route) => {
    router.use(route.path, route.route);
  });
}

module.exports = router;
