const jwt = require('jsonwebtoken');
const moment = require('moment');
const httpStatus = require('http-status');
const config = require('../config/config');
const userService = require('./user.service');
const { Token } = require('../models');
const ApiError = require('../utils/ApiError');
const { tokenTypes } = require('../config/tokens');

/**
 * Generate token
 * @param {ObjectId} userId
 * @param {Moment} expires
 * @param {string} type
 * @param {string} [secret]
 * @returns {string}
 */
const generateToken = (userId, expires, type, secret = config.jwt.secret) => {
  const payload = {
    sub: userId,
    iat: moment().unix(),
    exp: expires.unix(),
    type,
  };
  return jwt.sign(payload, secret);
};

/**
 * Save a token
 * @param {string} token
 * @param {ObjectId} userId
 * @param {Moment} expires
 * @param {string} type
 * @param {boolean} [blacklisted]
 * @returns {Promise<Token>}
 */
const saveToken = async (token, userEmail,metadata, expires, type, blacklisted = false) => {
  console.log("expires====>",expires)
  const tokenDoc = await Token.create({
    token,
    user: userEmail,
    metadata,
    expires: expires.toDate(),
    type,
    blacklisted,
  });
  console.log("tokenDoc",tokenDoc)
  return tokenDoc;
};

/**
 * Verify token and return token doc (or throw an error if it is not valid)
 * @param {string} token
 * @param {string} type
 * @returns {Promise<Token>}
 */
const verifyToken = async (token, type) => {
  const payload = jwt.verify(token, config.jwt.secret);
  console.log("payload",payload)
  const tokenDoc = await Token.findOne({ token, type, user: payload.sub, blacklisted: false });
  console.log("tokenDoc",tokenDoc)
  if (!tokenDoc) {
    throw new Error('Token not found');
  }
  return tokenDoc;
};

/**
 * Generate auth tokens
 * @param {User} user
 * @returns {Promise<Object>}
 */
const generateAuthTokens = async (user) => {
  const accessTokenExpires = moment().add(config.jwt.accessExpirationMinutes, 'days');
  console.log(accessTokenExpires);
  const accessToken = generateToken(user.email, accessTokenExpires, tokenTypes.ACCESS);
  // console.log(accessToken);

  const refreshTokenExpires = moment().add(config.jwt.refreshExpirationDays, 'days');
  console.log(refreshTokenExpires);
  const refreshToken = generateToken(user.email, refreshTokenExpires, tokenTypes.REFRESH);
  console.log(refreshToken)


  await saveToken(refreshToken, user.email,{}, refreshTokenExpires, tokenTypes.REFRESH);

  return {
    access: {
      token: accessToken,
      expires: accessTokenExpires.toDate(),
    },
    // refresh: {
    //   token: refreshToken,
    //   expires: refreshTokenExpires.toDate(),
    // },
  };
};
const generate2FAToken = async (user)=>{
  const accessTokenExpires = moment().add(config.jwt.two_FA,'minutes');
  console.log("accessTokenExpires",accessTokenExpires);
  const accessToken = generateToken(user.email, accessTokenExpires, tokenTypes.TWO_FA);
  return accessToken

}

/**
 * Generate reset password token
 * @param {string} email
 * @returns {Promise<string>}
 */
const generateResetPasswordToken = async (email) => {
  const user = await userService.getUserByEmail(email);
  if (!user) {
    throw new ApiError(httpStatus.NOT_FOUND, 'No users found with this email');
  }
  const expires = moment().add(config.jwt.resetPasswordExpirationMinutes, 'minutes');
  const resetPasswordToken = generateToken(user.email, expires, tokenTypes.RESET_PASSWORD);
  console.log(resetPasswordToken)
  await saveToken(resetPasswordToken, user.email,{} ,expires, tokenTypes.RESET_PASSWORD);
  return resetPasswordToken;
};

/**
 * Generate verify email token
 * @param {User} user
 * @returns {Promise<string>}
 */
const generateVerifyEmailToken = async (user) => {
  if (await Token.isEmailTaken(user.email,tokenTypes.VERIFY_EMAIL)) {
    throw new ApiError(httpStatus.BAD_REQUEST, 'Email already taken');
  }
  const expires = moment().add(config.jwt.verifyEmailExpirationDays, 'days');
  const verifyEmailToken = generateToken(user.email, expires, tokenTypes.VERIFY_EMAIL);
  await saveToken(verifyEmailToken, user.email,{email:user.email,password:user.password,phone:user.phone?user.phone:''}, expires, tokenTypes.VERIFY_EMAIL);
  return verifyEmailToken;
};

module.exports = {
  generateToken,
  saveToken,
  verifyToken,
  generateAuthTokens,
  generateResetPasswordToken,
  generateVerifyEmailToken,
  generate2FAToken
};
