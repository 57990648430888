const { loginUserWithEmailAndPassword } = require("../services/auth.service");
const { response } = require("../services/send.response");
const { updateSubscribtion, addCardService, MakeDefaultService } = require("../services/stripe.services");

const updateSubscription =async (req, res) => {
    const user = req.user
    let { planId, productId } = req.body;

    console.log('update subscrition',req.user,user,req.body);
    let updated = await updateSubscribtion(user, planId, productId)
    console.log("updated",updated)
    if (updated) {
        
        return res.send(
            response(true, updated.message, {})
        );
    }
    else {

        return res.send(
            response(false, updated.message, {})
        );

    }
}
const addCard =async (req, res) => {
    const user = req.user
    let { cardToken } = req.body;

    let updated = await addCardService(user,cardToken)
    console.log("updated",updated)
    if (updated) {
        
        return res.send(
            response(true, updated.message, {})
        );
    }
    else {

        return res.send(
            response(false, updated.message, {})
        );

    }
}
const MakeDefault =async (req, res) => {
    const user = req.user
    let { cardId } = req.body;

    let updated = await MakeDefaultService(user,cardId)
    if (updated) {
        
        return res.send(
            response(true, updated.message, {})
        );
    }
    else {

        return res.send(
            response(false, updated.message, {})
        );

    }
}
module.exports = {
    updateSubscription,
    addCard,
    MakeDefault
}